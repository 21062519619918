.skill {
    position: relative;
    background: #f7f7f7;
    border-radius: 9px;
    transition:all 0.3s ease-in-out;
}
.skill h4 {
    /* position: absolute; */
    
    color: #2f8079;
    right: 10px;
    padding: 5px 10px;
    font-size: 16px;
    transition: all 0.3s ease-in-out;
    left: auto;
    top: -11px;
}

 .skill .icon-img{
    padding: 3px !important;
    background: #fff !important;
 }
 .skill-div > div:first-child:before {
    position: absolute;
    content: '';
    background-color: #3081D0;
    width: 20px;
    height: 50%;
    left: -20px;
    bottom: 0;
    z-index: -1;
}
.skill-div > div:not(:first-child) {
    background-color: #f3f3f3;
    border-radius: 15px;
 }
 .skill-div > div:first-child {
    background-color: #253347;
    color: #fff;
    /* width: 20%; */
    position: relative;
    margin-left: auto;;
 }
 .skill-div > div:first-child:after {
    position: absolute;
    content: '';
    width: 20px;
    height: 20px;
    border-bottom: 20px solid #3081D0;
    bottom:50%;
    left: -20px;
    border-left: 20px solid transparent;
    /* border-right: 20px solid transparent; */
}
.sec-title:before{
    position: absolute;
    content: "";
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: rgb(48, 129, 208);
    bottom: -10px;
}
.sec-title:after{
    position: absolute;
    content: "";
    width: 30px;
    height: 8px;
    border-radius: 20px;
    background-color: rgb(48, 129, 208);
    bottom: -10px;
    left:10px;
}