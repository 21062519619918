.card{
    padding:20px;
}
.card-content{
    position:relative;
    
    border: 2px solid #f1f1f1;
    border-radius: 10px;
    background-color: #fff;
}
.card-content > div.my-img > img {
    position: relative;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    object-fit: cover;
    max-height: 250px;
    min-height: 250px;
    width: 100%;
}
.my-img{
    position:relative;
    overflow:hidden;
}
.mydiv{
    position: absolute;
    bottom: 0;
    left:0;
    transition: 0.3s all ease-in-out;
    z-index:-9;
}
.card-content:hover {
    box-shadow: 2px -8px 9px rgba(0,0,0,0.1);
    transition: 0.3s all ease-in-out;
}
.card-content:hover .mydiv{
    opacity: 1;
    visibility: visible;
    transition: 0.3s all ease-in-out;
    z-index:-9;
}

.card-content:hover .div1{
    left:-10%;
    bottom:0%;
}
.card-content:hover .div2{
    left:-10%;
    bottom:10%;
}
.card-content:hover .div3{
    left:-10%;
    bottom:20%;
}
.card-content:hover .div4{
    left:0%;
    bottom:-10%;
}
.card-content:hover .div5{
    left:10%;
    bottom:-10%;
}
.card-content:hover .div6{
    left:20%;
    bottom:-10%;
}
h4.h4head {
    /* position: relative;
    margin-bottom: 0;
    top: -22px; */
    /* background: #fff;
    width: 70%;
    left: 15%;
    right: 15%;
    padding: 10px;
    border-radius: 30px; */
}
/* h4.h4head:after {
    z-index: 2;
    position: absolute;
    content: '';
    display: block;
    left: 35%;
    right: 35%;
    margin: auto;
    width: 0;
    height: 4px;
    bottom: -25%;
    opacity: 0;
    visibility: hidden;
    transition: 0.4s all ease-in-out;
} */
.card-content:hover h4.h4head:after {
    width: 30%;
    height: 4px;
    right: 35%;
    left: 35%;
    bottom: -25%;
    background: #3081D0;
    visibility: visible;
    opacity: 1;
    transition: 0.4s all ease-in-out;
}