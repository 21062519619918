.gh {
    background-color: #52B4E2;
    color: #fff;
    border-radius: 20px;
    padding: 2px 13px;
    }
.card-desc{
    padding:10px;
}
.all-projs button{
    transition:0.3s ease all;
    border: 2px solid #3081D0;
    padding:7px;
}
.all-projs button:hover{
    background-color: #fff;
    transition: 0.3s ease all;
    color: #3081D0;
}
/* .my-img:before {
    top: 100%;
    position: absolute;
    content: '';
    border-left: 11px solid transparent;
    border-top: 11px solid rgb(48,129,208);
    border-right: 11px solid transparent;
    margin-left: auto !important;
    left: 50%;
    margin-left: auto !important;
    transform: translateX(-50%);
}
    .my-img:after {
        position: absolute;
        content: '';
        height: 3.5px;
        width: 100%;
        background-color: rgb(48, 129, 208);
        z-index: 1;
    } */