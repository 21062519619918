.work-title:after{
    content: '';
    position: absolute;
    /* background-color: #3081C8; */
    /* width: 5px; */
    /* height: 5px; */
    left: -13px;
    top: 50%;
    /* border-radius: 50%; */
    z-index: 1;
    transform: translateY(-50%);
    border-bottom: 7px solid transparent;
    border-left: 7px solid #253347;
    border-right: 7px solid transparent;
    border-top: 7px solid transparent;
}
.work-div > div {
    padding: 30px 50px;
    box-shadow: 3px 3px 10px rgba(0,0,0,0.1);
    border-radius: 10px;
}
.tech li {
    background: #3081d0;
    color: #fff;
    margin-right: 5px;
    padding: 7px 10px;
    border-radius: 14px;
    margin:3px;
}