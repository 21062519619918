.title-text:after{
    content:'';
    position:absolute;
    height: 7px;
    width: 7px;
    background-color: #3081D0;
    border-radius:50%;
    right:-13px;
    bottom: 6px;
}

nav a{
    position:relative;
}
nav a:hover:after {
    visibility: visible;
    right: auto;
    left:0;
    display: block;
    opacity:1;
    transition: 0.5s;
    width:100%;
}
nav a:after {
    position: absolute;
    width: 0px;
    height: 2px;
    border-radius: 10px;
    background-color: #3081d0;
    content: '';
    right: 0;
    left:auto;
    bottom: -10px;
    transition: 0.5s;
    visibility: hidden;
    opacity: 0;
}
a.nb-icon{
    position:absolute;
    top:20px;
    right:20px; 
    z-index:9;
    border-radius: 5px;
    padding:5px;
}

nav{
    transition:0.5s ease-in-out;
}
@media screen and (max-width:1100px) {
    body.no-scroll{
        overflow:hidden;
    }
    nav{
        position:fixed;
        transition:0.3s ease-in-out;
        height:100%;
        right:-100%;
        top:0;
        background-color: #fff;
        z-index: 9;
        justify-content:start;
        align-items:center;
        padding-top:50px;
    }
    nav a:hover:after{
        content:none !important;
    }
    .nav-visible{
        display: flex;
        transition: 0.3s ease-in-out;
        width:70%;
        right: 0%;
        top: 0;
        z-index: 9;
        margin: auto;
        justify-content: start;
    }
    a.navitem{
        display: block;
        width:100%;
        color:#253347;
        text-align:center;
        padding:10px 40px;
        font-weight:600;
        text-align:left;
    }
    .nav-visible a.navitem:not(:last-child){
        border-bottom:0.8px solid #f1f1f1;
    }
    .svg-close{
        transform:rotate(-45deg);
    }
    .nav-visible .svg-close{
        color:#fff;
        transform: rotate(45deg);
    }
    a.nb-icon span{
        height:2px;
        background:#a6a6a6;
        width:20px;
        transition:0.3s all ease-in-out;
    }
    span.bars{
        margin:2px;
    }
    span.close:nth-child(1){
        transform:translateY(2px) rotate(45deg);
        transition:0.3s all ease-in-out;
        opacity:1;
        visibility: visible;
    }
    span.close:nth-child(2){
        transition:0.3s all ease-in-out;
        opacity:0;
        visibility: hidden;
    }
    span.close:nth-child(3){
        transform:translateY(-2px) rotate(-45deg);
        transition:0.3s all ease-in-out;
        opacity:1;
        visibility: visible;
    }
    .img-div img{
        width:200px !important;
        height:200px !important;
    }
    .img-div:before{
        width:200px !important;
        height:200px !important;
    }
    .img-div:after{
        width:200px !important;
        height:200px !important;
    }
}