.socials{
    transition:0.3s all ease-in-out;
    position:relative;
    z-index: 3;
}
.socials:hover{
    transition:0.3s all ease-in-out;
    transform: translateY(-10px);
}
.send-button{
    transition: 0.3s all ease-in-out;
}
.send-button:hover{
    background-color: #3081D0;
    transition: 0.3s all ease-in-out;
}