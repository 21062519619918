
#about{
    display:none;
}
.img-div:before {
    position: absolute;
    width: 250px;
    height: 250px;
    background-color: #253347;
    border-radius: 50%;
    content: '';
    z-index: -1;
    top: 30px;
    left: 120px;
}
.img-div:after {
    position: absolute;
    width: 250px;
    height: 250px;
    background-color: #3081d0;
    border-radius: 50%;
    content: '';
    z-index: -1;
    top: 80px;
    right: 120px;
}

